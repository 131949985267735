.markedContainer {
    box-sizing:content-box;
    width: 100%;
    height: 80vh;
    margin: 8px;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.markedContainer > div {
    width: 50%;
    height: 80vh;
    border-radius: 5px;
    margin: 5px;
}

.marked {
    /* border-right: 2px solid rgb(144, 144, 144, 0.6); */
    border: none;
}

.markedShow {
    background-color: rgb(195, 195, 195);
}

#inputArea {
    border: none;
    width: 100%;
    height: 100%;
    resize: none;

    background: #e8e;
    color: #fff;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    overflow-y: scroll;
    line-break: loose;
    line-height: normal;
    
    border-radius: 5px;
}

.markedContainer_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

