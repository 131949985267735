.performance {
    box-sizing: border-box;
    width: 50%;
    min-height: 77vh;
    margin-left: 5px;
    border: 2px solid rgba(215, 215, 215, 0.8);
    border-radius: 5px;
    /* box-shadow: 2px 2px 2px 2px linear-gradient(to right, #e8e, transparent, #e8e); */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.performance > div {
    height: 35vh;
    border: 1px solid #e8e;
    background-color: #e8e;
    color: #fff;
    border-radius: 5px;
}

/* .performance div:nth-child(1) {
    background-color: #e8e;
    color: #fff;
} */

.performance div:nth-last-child(1) > p {
    font-size: 40px;
}


.header > h3 {
    text-align: center;
    font-weight: 600;
    font-size: 28px;
}

.editorContent {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
}

.editorContent > * {
    height: auto;
    width: 50%;    
}

.submitBtn {
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

