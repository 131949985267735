.edite_container {
    margin: 12px;
    padding: 0px;
    width: 100%;
}

.edite_header > h3 {
    text-align: center !important;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
}

.edite_header {
    border:none;
    border-bottom: 1px solid #000;
    margin-bottom: 8px;
}

.edite_container > section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edite_form {
    width: 50%;
    background-color: #fff;
    padding: 8px;
}

.edite_performance {
    margin-left: 12px;
    width: 50%;
    height: 200px;
    background-color: #fff;
}

