.loginContainer {
    margin: 0px;
    width: 100%;
    height: 100vh;

    background-image: url('../../../public/images/login.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-origin: border-box;
    background-attachment: fixed;
    background-blend-mode: luminosity;
    will-change: auto;
    z-index: -1;

    /* filter: grayscale(40%); */
}

.form {
    width: 100%;
    height: 100%;

    background-color: transparent;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login {
    width: 250px;
    height: 144px;
    border-radius: 5px;
    box-shadow: 0 12px 20px -10px rgb(156 39 176 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(156 39 176 / 20%);
    background: linear-gradient(60deg, #ab47bc, #8e24aa);

    color: #fff;
    z-index: 90;
}

.loginForm {
    box-sizing: border-box;
    width: 280px;
    height: 500px;
    background-color: #fff;

    border: none;
    border-radius: 5px;

    margin-top: -90px;
    z-index: 8;
}

.login > h3 {
    text-align: center;
    margin-top: 40px;
    font-size: 20px;
    font-weight: 500;
}

.login > div {
    width: 100%;
    height: 1px;
    border: none;
    /* background-color: #fff; */
    /* background: linear-gradient(to right, #fff, transparent, #fff); */
    transform: translateY(-30px);
}
