@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap');

.notFoundCOntainer {
    background-image: url('../../../public/images/lerone-pieters-Auas-unsplash.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-origin: border-box;
    background-attachment: fixed;
    will-change: auto;
}

.notFound {
    width: 90%;
    height: 120vh;
    margin: auto;
    border: 2px solid rgba(182, 167, 167, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);

    font-size: 80px;
    font-weight: 700;
    font-family: 'Ma Shan Zheng', cursive;
    color: #fff;
}

.notFound > span {
    cursor: pointer;
}

.notFound > span::selection {
    color: #219ebc;
}
