#root,
body {
    background-color: #d9d9d9;
}

.navHeaders {
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    min-height: 60px;

    /* background-color: #fff; */
    background-color: transparent;

    position: fixed;
    opacity: 1;
    border-radius: 0 0 5px 5px;
    color: #fff;

    z-index: 999;
}

.navHeadersTitle {
    flex: 1;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    padding: 8px 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    animation: tada 3s, ClickActive 0.5s;
}

.navHeadersTitle:active {
    animation: none;
}

.navListMenu {
    list-style: none;
    text-transform: uppercase;

    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    display: inline-flex;
    justify-content: space-between;
}
.navListMenu > li {
    display: block;
    margin-left: 50px;
    cursor: pointer;
    animation: tada 3s, ClickActive 0.5s;
}
.navListMenu > li:active {
    animation: none;
}

/* icon */
.iconsLink {
    color: #fff;
}

/* 滚动变色 */
.scrollBodyColor {
    color: #000;
    background-color: #fff;
}
.scrollIconsLink {
    color: #000;
}

.headerImage {
    margin: 0;

    width: 100%;
    height: 720px;

    /* background-color: brown; */
    background-image: url('../../../public/images/medium-pawan-thapa-.jpg');
    object-fit: cover;
    /* background-size: 100% 100%; */
    background-size: cover;
    /* background-attachment: scroll; */
    will-change: auto;
    background-repeat: no-repeat;
    border-radius: 0 0 10px 10px;
    /* filter: brightness(1.2) saturate(0.8) hue-rotate(10deg) contrast(0.8) opacity(0.8); */
    display: block;
}

.headerImage_next {
    width: 60px;
    height: 60px;
    margin: 0 auto 0;
    background-color: transparent;
    transform: translate(0, 600px) rotate(45deg);

    border: 10px solid transparent;
    border-bottom-color: #0098f0;
    border-right-color: #0098f0;

    cursor: pointer;
}

.headerImage_next > div {
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    border: 5px solid transparent;
    border-bottom-color: linear-gradient(to top left, rgb(135 116 249), rgb(75 162 237));
    border-right-color: red;
    /* margin: -5px; */
}

.headerImage_next > div > div {
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    border: 5px solid transparent;
    border-bottom-color: red;
    border-right-color: red;
}

.content {
    box-sizing: border-box;

    margin: auto;
    /* margin-top: -300px; */
    overflow: hidden;

    width: 93%;
    height: 180vh;

    margin-bottom: -280px;

    transform: translate(0, -280px);

    background-color: #ffffff;

    border-radius: 10px;
    box-shadow: 2px 2px 5px 3px #af9ca0;
}

.NavFooter {
    height: 60px;

    padding: 10px;
    /* margin-top: -80px; */
    transform: translate(0, -80);

    display: flex;
    justify-content: space-around;
    align-items: center;

    flex-direction: column;
}

.profileImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilePic {
    width: 200px;
    height: 200px;
    object-fit: cover;
    background: linear-gradient(to top left, rgb(135 116 249), rgb(75 162 237));
    border-bottom: 1px solid rgba(211, 204, 204, 0.4);
    box-shadow: 10px -10px 20px rgba(18, 167, 217, 0.2), -10px 10px 20px rgba(255, 255, 255, 0.1);
    filter: brightness(1.2);

    border-radius: 50%;
    transform: translateY(-180px);
    z-index: 10;
}

.resumeName {
    /* align-items: center; */
    color: #3c4858;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transform: translateY(110px);
}

.resumeName > h3 {
    font-size: 35px;
    font-weight: 800;
}

.resumeName > h6 {
    margin-top: -10px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.resumeName div > span {
    margin: 5px;
    cursor: pointer;
    animation: ClickActive 0.3s;
}

.selfInstro {
    color: #999;
    max-width: 600px;
    margin: 4rem auto 0;
    text-align: center;
}

.selfInstro p {
    font-size: 14px;
}

.portfolio {
    max-width: 600px;
    margin: 4rem auto 0;
}

.cardPotfolio {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(2);
}

.cardPotfolio div {
    box-sizing: border-box;
    margin: 0 10px;
    cursor: pointer;
    padding: 10px;
    width: 50px;
    overflow: visible;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #555;
    transform: translate(0, 50px);
}

/* 选择 */
.selectedProto {
    color: #fff !important;
    background-color: #9c27b0;
}

.picFlex {
    max-width: 600px;
    margin: 4rem auto 0;
    margin-top: 180px;
}

.picFlex > div {
    /* max-width: 100%; */
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.picFlex img {
    max-width: 200px;
    margin: 30px;

    border-radius: 15px;
    object-fit: cover;
}

@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes ClickActive {
    from {
        background: linear-gradient(to right, transparent);
    }
    10% {
        background: linear-gradient(to right, rgba(173, 172, 176, 0.9) 10%, transparent 10%);
    }
    30% {
        background: linear-gradient(to right, rgba(173, 172, 176, 0.9) 30%, transparent 30%);
    }
    50% {
        background: linear-gradient(to right, rgba(173, 172, 176, 0.9) 50%, transparent 50%);
    }
    60% {
        background: linear-gradient(to right, rgba(173, 172, 176, 0.9) 60%, transparent 60%);
    }
    80% {
        background: linear-gradient(to right, rgba(173, 172, 176, 0.9) 80%, transparent 80%);
    }
    to {
        background: rgba(173, 172, 176, 0.9);
    }
}

.btnShow {
    width: 80%;
    height: 110vh;
    background-color: #171941;

    margin: 25px auto 0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(173, 172, 176, 0.9), -2px -2px 2px 1px rgba(173, 172, 176, 0.9);
}
.btnShow > button {
    margin: 10px;
}

.purpleBtn_default,
.purpleBtn_round,
.purpleBtn_circle,
.purpleBtn_transparent,
.purpleBtn_small,
.purpleBtn_large,
.purpleBtn_status_default,
.purpleBtn_status_info,
.purpleBtn_status_success,
.purpleBtn_status_warning,
.purpleBtn_status_danger,
.purpleBtn_status_neutral {
    width: 150px;
    min-height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border-width: 0.5px;
    cursor: pointer;

    background: #9c27b0;
    background-image: linear-gradient(to bottom left, #9c27b0, #ba54f5, #9c27b0);
    background-size: 210% 210%;
    background-position: 100% 0;
    transition: all 0.15s ease;

    box-shadow: none;
    color: #fff;

    font-weight: 500;
    font-size: 1rem;
    line-height: 1.35rem;
    text-align: center;
    text-transform: capitalize;
}

.purpleBtn_round {
    border-radius: 40px;
}

.purpleBtn_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 0.5rem;
    font-weight: 300;
    transform: scale(0.8);
}

.purpleBtn_transparent {
    color: #e14eca;
    /* mix-blend-mode: difference; */
    border-width: 1.5px;
    border-color: #e14eca;
    border-radius: 45px;
    background: 0 0;

    font-size: 15px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.purpleBtn_small {
    width: 100px;
    min-height: 40px !important;
    font-size: small;
}

.purpleBtn_large {
    width: 160px;
    min-height: 60px;
}

.purpleBtn_status_default {
    background: #344675;
    background-image: linear-gradient(to bottom left, #344675, #263148, #344675);
    background-size: 210% 210%;
    background-position: 100% 0;
    background-color: #344675;
    transition: all 0.15s ease;
    color: #fff;
    box-shadow: none;
}

.purpleBtn_status_info {
    background: #1d8cf8;
    background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
    background-color: #1d8cf8;
}

.purpleBtn_status_success {
    background: #00f2c3;
    background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    background-color: #00f2c3;
    font-weight: 550;
    font-size: 18px;
}

.purpleBtn_status_warning {
    background: #ff8d72;
    background-color: #ff8d72;
    background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
    font-weight: 550;
}

.purpleBtn_status_danger {
    background: #fd5d93;
    background-color: #fd5d93;
    background-image: linear-gradient(to bottom left, #fd5d93, #ec250d, #fd5d93);

    font-weight: 580;
    font-size: 17px;
}

.purpleBtn_status_neutral {
    background: #fff;
    background-color: #fff;
    background-image: linear-gradient(to bottom left, #fff, #fff, #fff);
    color: #e14eca;

    font-weight: 500;
    font-size: 15px;
}

.purpleBtn_default > svg,
.purpleBtn_round > svg,
.purpleBtn_circle > svg,
.purpleBtn_transparent > svg,
.purpleBtn_small > svg,
.purpleBtn_small > svg,
.purpleBtn_large > svg,
.purpleBtn_status_default > svg,
.purpleBtn_status_info > svg,
.purpleBtn_status_success > svg,
.purpleBtn_status_warning > svg,
.purpleBtn_status_danger > svg,
.purpleBtn_status_neutral > svg {
    margin-bottom: -2px;
    margin-right: 2px;
}

.purpleBtn_default:hover,
.purpleBtn_round:hover,
.purpleBtn_small:hover,
.purpleBtn_large:hover,
.purpleBtn_status_default:hover,
.purpleBtn_status_info:hover,
.purpleBtn_status_success:hover,
.purpleBtn_status_warning:hover,
.purpleBtn_status_danger:hover,
.purpleBtn_status_neutral:hover {
    transform: translateY(-2px);
}
.purpleBtn_default:active,
.purpleBtn_round:active,
.purpleBtn_small:active,
.purpleBtn_large:active,
.purpleBtn_status_default:active,
.purpleBtn_status_info:active,
.purpleBtn_status_success:active,
.purpleBtn_status_warning:active,
.purpleBtn_status_danger:active,
.purpleBtn_status_neutral:active {
    transform: translateY(2px);
}

/* 缩小 */
.purpleBtn_circle:hover {
    transform: translateY(-2px);
}

.purpleBtn_circle:active {
    transform: translateY(2px);
}
/* .purpleBtn_status_neutral特殊处理 */
.purpleBtn_status_neutral:hover {
    color: #8965e0;
}

.purpleBtn_status_neutral:active {
    color: #212529;
}

/* Links */
.Links_default,
.Links_primary,
.Links_info,
.Links_success,
.Links_warning,
.Links_danger {
    border: none;
    box-shadow: none;
    cursor: pointer;
    color: #344675;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    background: transparent;
}

.Links_primary {
    color: #e14eca;
}

.Links_info {
    color: #1d8cf8;
}

.Links_success {
    color: #00f2c3;
}

.Links_warning {
    color: #ff8d72;
}

.Links_danger {
    color: #fd5d93;
}

.Links_default:hover,
.Links_primary:hover,
.Links_info:hover,
.Links_success:hover,
.Links_warning:hover,
.Links_danger:hover {
    color: #fff;
    transform: translateY(-2px);
}

.Links_default:active,
.Links_primary:active,
.Links_info:active,
.Links_success:active,
.Links_warning:active,
.Links_danger:active {
    color: #fff;
    transform: translateY(2px);
}

.transparentBtn_transparent {
    color: #0098f0;
    border-color: #3ea1ec;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    cursor: pointer;
    padding: 10px 22px;
    border: 2px solid #344675;
    border-radius: 5px;

    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
}

.transparentBtn_transparent:hover {
    transform: translateY(-2px);
}

.transparentBtn_transparent:active {
    color: #023e8a;
    border-color: #023e8a;
    transform: translateY(2px);
}

.gross_container {
    color: #fff;
}

.gross {
    /* display: inline-block; */
    position: absolute;
    width: 80px;
    height: 80px;
    background: transparent;
    border-radius: 15px;
    border: 1px solid rgba(173, 172, 176, 0.9);
    /* backdrop-filter: blur(4px); */
}

.selection {
    border: none;
    align-items: center;
    font-size: 16px;
    text-align: center;
    margin: 0px;
    font-weight: 430;
    cursor: pointer;
}

/* .selection > option {
    color: #0098f0;
} */
