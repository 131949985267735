* {
    margin: 0px;
    padding: 0px;
}

.infosBody {
    width: 100%;
    height: 180vh;
    background-image: url('../../../public/images/clay-banks-zH9kK6wNC20-unsplash.jpg');
    background-size: 100% 100%;
    background-attachment: fixed;
    filter: brightness(80%) sepia(20%);
    fill-opacity: 0.8;
}

.sliders {
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, rgba(90, 149, 207, 0.5), rgba(111, 121, 134, 0.8), rgba(99, 101, 102, 0.5), rgba(113, 113, 113, 0.8));
}

.start {
    margin: 0 auto 0;

    width: 100%;
    height: 100vh;
    background: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: startDisappear 5.5s linear infinite;
}

.start > * {
    color: #fff;
}

.se {
    position: absolute;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100vh;
    background: greenyellow;

    font-size: 35px;
}

@keyframes startDisappear {
    from {
        width: 100%;
        height: 100vh;
        font-size: 25px;
    }

    10% {
        width: 90%;
        height: 80vh;
        border-radius: 25px;
    }

    to {
        width: 40%;
        height: 40vh;
    }
}
