.richEditor {
    width: auto;
    height: 100%;
    background-color: #fff;
}

.editeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;

}

.edite {
    margin: 5px;
    width: 50%;
    height: auto;
    background: transparent;


    background-color: #e8e;
    color: #fff;
}