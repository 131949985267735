@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap');

.text_container {
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    background: #fff;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
}

.text_area {
    height: 100%;
    width: 50%;
    resize: none;
    border: .9px solid;
    border-radius: 5px;
    border-image: linear-gradient(to bottom, #e8e, transparent, #e8e);
    background-color: #e8e;
    color: #fff;
    font-family: monospace;
    font-size: 25px;
    line-height:120%;
    line-break: loose;
}

.text_performance {
    margin-left: 5px;
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    word-wrap: break-word;
    font-size: 25px;
    line-height:120%;
    line-break: loose;
    font-family: 'Ma Shan Zheng', cursive;
}

.text_old_version {
    margin: 10px;
    width: 400px;
    height: 300px;

    background-color: aquamarine;
}

