.todolist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.todolist > span {
    cursor: pointer;
    padding: .2rem 6rem;
    border: 1px solid rgba(167, 165, 165, 0.8);
    border-radius: 5px;
    background: #e8e;
    color: #fff;
    transition: .5s tdata;
    display: block;
}

.todolist > span:hover {
    background-color: #1890ff;
    transform: translateY(-2px);
}


.todolist > span:active {
    background-color: #1890ff;
    transform: translateY(2px);
}

@keyframes tdata {
    from {
        border-bottom: 5px solid;
        border-image: linear-gradient(to right, #3ef372 10%, transparent);
    }

    40% {
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, #3ef372 50%, transparent);
    }

    80% {
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, #3ef372 80%, transparent);
    }

    100% {
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, #3ef372 90%, transparent);
    }
}