@import url('https://fonts.googleapis.com/css2?family=Kalam&family=Ma+Shan+Zheng&family=Righteous&family=Tai+Heritage+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&family=Righteous&display=swap');

h1, h2, h3, h4, h5, h6 {
    color: #3d4449;
    font-family: "Roboto Slab",serif;
    font-weight: 700;
    line-height: 1.5;
}

.blog_container > * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    transition: all .5s;
}

.blog_container {
    width: 100%;
    height: 130vh;
    background-color: rgba(255, 255, 255, 0.8);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.blog_menu_inner {
    height: 100%;
    padding: 1.67em 1.67em 1.3em 1.6em;
}

.blog_menu_inner > hr {
    border: none;
    background: #d9d9d9;
    height: 2px;
    margin-top: -2px;
    margin-bottom: 1.5em;
}

.blog_menu_sider {
    width: 230px;
    height: 100%;
    background-color: #f0f0f0;
    margin: 0 0 3.5em 0;
    padding: 0 0 3.5em 0;
    box-shadow: 6px 6px 6px 6px #888686b0;
    border-right: 1.5px solid #fff;
    border-radius: 5px;

    float: left;
    z-index: 9;
}

.blog_menu_sider_close {
    content: '';
    width: 0px;
    height: 100%;
    transition: all 2s;
    transform: translateX(-300px);
    position: absolute;
    visibility: hidden;
}

.blog_menu_header {
    cursor: pointer;
    padding: 20px;
    text-align: center;
    font-size: 35px;
    font-family: 'Righteous', cursive;
    border-bottom: solid 3px #f56a6a;
}

.blog_menu_sider ul {
    margin: 0 0 2em 0;
    list-style: none;
    user-select: none;
    color: #3d4449;
    letter-spacing: .075em;

    text-transform: uppercase;
    font-size: 1em;
    font-family: 'Tai Heritage Pro', serif;
}

.blog_menu_sider li {
    margin-top: 1.4em;
    margin-left: .5em;
    padding-bottom: .5em;
    cursor: pointer;
    border-bottom: 1.4px solid #d9d9d9;
}

.blog_menu_sider ul>:last-child {
    border-bottom: none;
}


.blog_menu_infos  h3 {
    font-size: 1.3em;
    cursor: default;
    margin-left: .2em;
    padding: 0 .8em .4em .2em;
    display: inline-block;
    border-bottom: solid 3px #f56a6a;
}

.blog_menu_infos ul {
    font-size: 12px;
    letter-spacing: .4px;
    color: #7F888F;
    text-transform: unset;
}

.blog_menu_infos ul a {
    color: #7F888F;
}


.blog_menu_infos ul a:hover {
    border-bottom:.2em solid #f56a6a;
    color: #f56a6a;

}

.blog_content_sider {
    width: calc(100% - 230px);
    height: 100%;
    transition: width 2s;
    transition-delay: .4s;
    z-index: 1;
    display: inline-block;
}

.blog_content_sider > * {
    padding-left: 1.5em;
}

.blog_content_sider_expand {
    width: 100%;
}

.blog_container 
li:hover, 
.blog_menu_header:hover,
.blog_menu_infos h3:hover,
.blog_content_title h3:hover,
.blog_content_ul:first-child {
    color: #f56a6a;
}

.blog_content_collaspe::after {
    content: '';
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border-top: 5px solid;
    border-bottom: 5px solid;
    border-color: #f56a6a;
    background-color: #f56a6a;
    margin-top: 2.5em;
    padding: 5px 0;
    background-clip: content-box;
}

.blog_content_title {
    width: 100%;
    height: 120px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog_content_title > h3 {
    text-align: center;
    font-size: 30px;
    font-family: sans-serif;
    text-transform: uppercase;
    margin-bottom: 0px;
    cursor: pointer;
    user-select: none;
}

.blog_content_ul {
    width: 90%;
    display: inherit;
    justify-content: space-between;
    
    border-bottom: solid 5px #f56a6a;
    margin-bottom: -30px;
}

.blog_content_ul ul {
    list-style: none;
    display: inline-flex;
    transform: scale(1.3);
    user-select: none;
}

.blog_content_ul li {
    margin-left: 5px;
    cursor: pointer;
}

.blog_content_content {
    width: 90%;
    margin-left: 40px;
    min-height: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: solid 2px #d9d9d9;
}
.blog_content_content > header {
    display: inline-block;
    margin-top: 2.5em;
    border-bottom: solid 3px #f56a6a;
}

.blog_content_content h3 {
    font-size: 1.75em;
    font-weight: 700;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.blog_content_sider > footer {
    display: block;
    width: 90%;
    margin-left: 20px;
}

.blog_content_pagnition {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3em 0;
    color: #7f888f;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    user-select: none;
}

.blog_content_post {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 6em);
}

.blog_content_article {
    display: block;
    /* background-color: aqua; */
    width: calc(50% - 6em);
    height: 500px;
    margin: 10px;
}

/* .blog_content_article::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 300px;
    background-color: #f56a6a;
} */
